
@font-face {
    font-family: Montserrat;
    src: url("../assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: Montserrat-Bold;
    src: url("../assets/fonts/Montserrat-Bold.ttf");
}

.projectsList {
    display: flex;

    flex-direction: column;

    gap: 50px;


    margin-left: 5%;
    align-items: flex-start;

}

.linkArt {
    font-size: 5vw;
    text-align: right;
    text-decoration: underline;
    margin-right:5%;
    margin-top:-2%;

}
#unipays.png {
    height: 267px;
    width: 265px;
}

#adyoblue\.png {
    margin-left: -10px;
    width: 140px;
}



.projectItem {


    font-size: 1.8em;
    display: flex;
    flex-direction: row;
    line-height: 180%;
    z-index: 0;

}
.headline {
    
    
    text-align: center;
    font-family: Montserrat-Bold;
    font-size: 2.75em;
    margin-bottom: 50px;

}
.projectItemText {
    
    width: 80%;
    margin-left: 3%;
    gap: 10px;
    font-family: Montserrat;
}

.projectItemText p {
    margin: 5px; 
    display: inline-block;   
}

.drawingPage {
    font-family: Montserrat;
    display: flex;
    padding-top: 100px;
}

.skills {
    margin-left: 5%;
    font-size: 1.9em;
    width: 37%;
}

.iuse {
    text-decoration: underline;
    font-size: 1.8em;
}

.slist li{
    margin-top: 5%;
}

.outerCanvasOff {
    display: none;
}


@media all and (min-width:900px) {
    .paint {
        display: flex;
        flex-direction: column;
        width:63%;
    }
}

.outerCanvas {
    display:flex;
    background: #C1DDFF;
    border-radius: 40px;
    height: 70%;
    margin-right: 10%;
    align-self: center;
    width:90%;
}

.black { 
    border-radius: 50%;
    background-color: black;
    height: 30px;
    width: 30px;
    
    margin-top: 10%;
    align-self: center;
}
.blue {
    border-radius: 50%;
    background-color: #1477F0;
    height: 30px;
    width: 30px;
    
    margin-top: 50%;
    align-self: center;
}
.slidD {
    width: 10%;
    display: flex;
    flex-direction: column;
}
.slider {
    display: flex;
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical;
    justify-content: center;
    background-color: black;
    border-radius: 15px;
    height: 50%;
    width: 15%;
    align-self: center;
    margin-top: 50%;
}
.thumb {
    -webkit-appearance: none;
    background-color: #ecf0f1;
    border: 1px solid #bdc3c7;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
  }

.thickImg img{
    width:80%;
    height: auto;
}
.thickImg{
    display: flex;
    justify-content: center;
    margin-top: 50%;
}
.artist {
    font-size: 1.9em;
    margin-top: 4%;
    text-align: center;
    padding-right: 10%;
    margin-bottom: 5%;
}

.galleryPage {
    font-family: Montserrat;
}
.links {
    font-size: 5vw;
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 5%;
}
.nodeco {
    text-decoration: none;
    color:#1477F0;
}

.linksList {
    list-style: none;
    margin-block-start: 0;
}
.linksList li {
    margin-bottom: 5%;
}
.footer {
    width:100%;
    background-color: #C1DDFF;
    height: 100px;
}

@media all and (min-width:0px) and (max-width: 900px) {
    .paint {
        display: none;
    }
    .skills {
        width: 90%;
        align-self: center;
        font-size: 5vw;
        margin-right: 5%;
    }
    .projectItemPhoto {
        width: 100%;
    }
    .projectItemDivPhoto {
        width: 14%
    }
    .projectItem {
        font-size: 5vw;
        margin-right: 5%;
    }
    .linkArt {
        display: none;
    }
    .links {
        margin-left: 0%;
        font-size: 4vw;
    }
}