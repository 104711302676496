.gallery {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    max-width: 100%;
    height: 300px;
}
@media screen and (max-width: 768px) {
    .gallery {
        display: none;
    }
}