
@font-face {
    font-family: Montserrat;
    src: url("../assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
    font-family: Montserrat-Bold;
    src: url("../assets/fonts/Montserrat-Bold.ttf");
}

body, html {
  height: 100%;
  background: #fff;
}

.container {
  width: 100%;
  height: 100%;
  margin: 0 0 0 0;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  background: #fff;
}

.pulse-button {
  position: relative;
  top: 40%;
  left:20%;
  
  display: block;
  width: 50%;
  height: 100px;
  font-size: 1.5em;
  font-family: Montserrat;
  
  text-align: center;
  line-height: 100px;
  letter-spacing: -1px;
  color: black;
  border: none;
  border-radius: 150px;
  background: rgb(170, 207, 252);
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px rgb(170, 207, 252,1);   
  
  animation: pulse 1.5s infinite;
}
.pulse-button:hover {
  animation: none;
}

.pulse-clicked {
  display: none;
}

@keyframes pulse {
  0% {
    transform:scale(0.9);
  }
  70% {
    transform : scale(1);
    box-shadow: 0px 0px 0px 50px rgb(170, 207, 252,0);
  }
    100% {
    transform : scale(.9);
    box-shadow: 0 0 0 0 rgb(170, 207, 252,0);
  }
}